export function initForm(options) {
  const handle = async (event) => {
    event.preventDefault()

    options.form.classList.remove('failed')
    options.form.classList.add('sending')
    event.submitter.disabled = true

    let res

    try {
      res = await fetch(options.sendTo, { 
        method: 'POST',
        body: new FormData(options.form)
      })
    }
    catch (error) {
      event.submitter.disabled = false
      options.form.classList.replace('sending', 'failed')

      return
    }

    if (!res.ok) {
      event.submitter.disabled = false
      options.form.classList.replace('sending', 'failed')
      
      return
    }

    setTimeout(
      () => {
        options.form.reset()
        document.location.href = options.redirectTo
      },
      1000
    )
  }

  options.form.addEventListener('submit', handle)
}
